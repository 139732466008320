<template>
  <div class="flex h-full flex-col">
    <v-header>
      <template v-if="route.name !== 'auth'">
        <template v-if="userStore.data">
          <span>{{ userStore.data.email }}</span>
          <v-button
            variant="primary"
            @click="userStore.logout"
          >
            Logout
          </v-button>
        </template>
        <v-button
          v-else
          variant="primary"
          to="/auth"
        >
          Login
        </v-button>
      </template>
    </v-header>

    <v-container class="flex flex-grow gap-8">
      <v-menu class="shrink-0 basis-[18rem]">
        <v-menu-group title="General">
          <v-menu-item to="/overview">Overview</v-menu-item>
          <v-menu-item to="/verify-identity">Verify identity</v-menu-item>
          <v-menu-item to="/change-password">Change password</v-menu-item>
          <v-menu-item :to="`${config.public.publisherAreaURL}/auth?st=${staffToken}`">Publisher area</v-menu-item>
        </v-menu-group>
      </v-menu>

      <main class="flex-grow">
        <slot />
      </main>
    </v-container>
    <v-footer />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const config = useRuntimeConfig()

const userStore = useUserStore()
const { staffToken } = storeToRefs(useTokensStore())
</script>
